<template>
<div>
  <div class="container main-catalog">
    <div class="details-button--exit studio-exit">
      <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/catalog')" class="button-exit--img"></a>
      <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/catalog')">{{$t('catalogDetails.exit')}}</a>
    </div>
    <div class="intro-catalog row row-cols-1 row-cols-sm-1 row-cols-lg-1 row-cols-xxl-2">
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xxl-8">
        <span>{{$t('studioCatalog.h1-1')}}</span>
      </div>
    </div>
    <div class="main-catalog--search">
      <input type="text" :placeholder="$t('studioCatalog.search-placeholder')" v-model="searchName" >
      <img src ="/images/interface/main_catalog/search.png" class="icon-search" aria-hidden="true">
    </div>
    <div class="main-catalog--content">
      <div class="row row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xxl-6">
        <div class="col item-row item-row-1" v-for="m in manufacturers" :key="m.name">
          <router-link :to="{ name: 'StudioDetails', params: {id: m.id, fromCatalog: true}}">
            <div class="item-row--img item-row--img-1 item-studio">
              <img :src="m.logo" alt="">
            </div>
            <!-- <div class="item-row--from item-row--from-1">
              <span class="item-row-link">{{m.name}}</span>
            </div> -->
            <div class="item-row--name item-row--name-1 item-row-studio--name">
              <span class="item-row-link"> {{m.name}}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <ExpoFooter></ExpoFooter>
</div>
</template>
<script>
import store from "@/store";
import {addParamsToLocation} from "@/utils/url";
import ExpoFooter from "@/components/ExpoFooter";
import {tr} from "@/utils";
export default {
  name: "Studio-Catalog",
  components: {
    ExpoFooter,
  },
  data() {
    return {
      searchName: this.$route.query.name ?? "",
    }
  },
  watch: {
    // Следим за изменениями даты для поиска и добавляем ее в квери адресса
    // И наоборот, синхроним дату с изменением квери в адрессе
    // Чтобы работал поиск по ссылке и история
    searchName(value) {
      addParamsToLocation({'name': value})
    },
    '$route.query.name'(v) {
      this.searchName = v ?? ''
    }
  },
  computed: {
    allmanufacturers() {
      return store.state.expoManufacturers.map(obj => tr(obj, this.$i18n.locale));
    },
    manufacturers() {
      return this.allmanufacturers.filter(obj => {
        obj = tr(obj, this.$i18n.locale);
        if (this.searchName.length !== 0) {
          if (!obj.name.toLowerCase().includes(this.searchName.toLowerCase()))
           return false;
        }
        return true;
      })
    },
  },
}
</script>

<style scoped>
.studio-exit{
  margin-top: 12px;
}
.item-studio img{
  width: 100%;
  height: 175px;
  object-fit: contain;
  user-select: none;
}
.item-row-studio--name{
  padding-top: 24px;
}
@media (max-width: 425px) {
  .studio-exit{
    margin-bottom: 24px;
  }
}
</style>
